import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { logMessage } from '../util/UtilApis';
import SignatureGenerator from './SignatureGenerator';
import { encryptCardNumber } from './CardNumberEncrypter';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 6,
  },
}));

const BlockContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #007BFF',
  borderRadius: 6,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  position: 'relative',
}));

const AttachedLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '-10px',
  left: '12px',
  backgroundColor: '#fff',
  padding: '0 4px',
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

const NegativeList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    expiryDate: null,
    instrumentType: 'P1',
    instrumentStatus: '60',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [signature, setSignature] = useState('');
  const [encryptedCardNumber, setEncryptedCardNumber] = useState('');
  const [header, setHeader] = useState({});
  const [body, setBody] = useState({});

  // Encrypt card number and update state
  useEffect(() => {
    const encryptAndSetCardNumber = async () => {
      try {
        if (formData.cardNumber) {
          const encryptedValue = await encryptCardNumber(formData.cardNumber);
          setEncryptedCardNumber(encryptedValue);
          logMessage('INFO', 'Card number encrypted successfully.', 'NegativeList');
        } else {
          setEncryptedCardNumber('');
        }
      } catch (error) {
        logMessage('ERROR', `Failed to encrypt card number: ${error.message}`, 'NegativeList');
      }
    };

    encryptAndSetCardNumber();
  }, [formData.cardNumber]);

  // Update header and body whenever formData or encryptedCardNumber changes
  useEffect(() => {
    const newHeader = {
      apiVersion: '3.0.0',
      srcSysId: 'nets',
      srcSysRef: `${dayjs().format('YYYYMMDDHHmmssSSS')}1234567890123`,
      srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };

    const newBody = {
      transactionId: '123456789012',
      transactionTime: dayjs().format('YYYY-MM-DDTHH-mm-ss[Z]'),
      institutionId: props.institutionId,
      cardNumber: encryptedCardNumber,
      cardSequenceNumber: formData.cardSequenceNumber,
      authorizationType: 'N',
      functionCode: '301',
      taggedDataBuffer: generateTaggedDataBuffer(),
    };

    setHeader(newHeader);
    setBody(newBody);
  }, [encryptedCardNumber, formData]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateFields()) {
        if (!signature) {
          logMessage('WARNING', 'Signature has not been generated yet.', 'NegativeList');
          return false;
        }

        const payload = {
          header,
          body,
          trailer: {
            signature,
          },
        };

        logMessage('USER_AUDIT', 'Generated payload for form submission with signature.', 'NegativeList');
        console.log('Generated Payload:', payload);

        if (props.onSuccess) {
          props.onSuccess('Card submitted successfully!');
        }

        return payload;
      } else {
        logMessage('WARNING', 'Form validation failed. Payload generation aborted.', 'NegativeList');
        return false;
      }
    },
    resetForm: () => {
      setFormData(initialFormData);
      setErrors({});
      logMessage('INFO', 'Form has been reset to initial state.', 'NegativeList');
    },
  }));

  const handleSignatureGenerated = (generatedSignature) => {
    setSignature(generatedSignature);
    logMessage('INFO', 'Signature has been generated and set in trailer.', 'NegativeList');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    logMessage('INFO', `Field '${name}' updated with value: ${value}`, 'NegativeList');
  };

  const validateFields = () => {
    const newErrors = {};

    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
    } else if (!isValidLuhn(formData.cardNumber)) {
      newErrors.cardNumber = 'Card number failed the Luhn check.';
    }

    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      newErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
    }

    if (!formData.expiryDate) {
      newErrors.expiryDate = 'Card expiry date is required.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const isValidLuhn = (number) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  const generateTaggedDataBuffer = () => {
    const tags = [
      { tag: '01', value: formData.instrumentType, type: 'string' },
      { tag: '02', value: formData.instrumentStatus, type: 'number' },
      { tag: '06', value: dayjs(formData.expiryDate).format('YYYYMM'), type: 'number' },
    ];

    return tags
      .map(({ tag, value, type }) => {
        const length = value.length.toString().padStart(3, '0');
        const formattedValue =
          type === 'number'
            ? value.padStart(parseInt(length, 10), '0')
            : value.padEnd(parseInt(length, 10), ' ');
        return `${tag}${length}${formattedValue}`;
      })
      .join('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <BlockContainer>
            <AttachedLabel>Instrument Status</AttachedLabel>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                name="instrumentStatus"
                value={formData.instrumentStatus}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="60" control={<Radio />} label="Lost (60)" />
                <FormControlLabel value="70" control={<Radio />} label="Stolen (70)" />
                <FormControlLabel value="80" control={<Radio />} label="Closed (80)" />
                <FormControlLabel value="20" control={<Radio />} label="Retriable for LTA ABT (20)" />
              </RadioGroup>
            </FormControl>
          </BlockContainer>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            variant="outlined"
            value="P1"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <SignatureGenerator header={header} body={body} onSignatureGenerated={handleSignatureGenerated} />
      </Grid>
    </LocalizationProvider>
  );
});

export default NegativeList;
