import forge from 'node-forge';

/**
 * Encrypts data using an RSA public key with RSA-OAEP and SHA-512 padding,
 * and formats the output as a compact JWE string.
 * @param {string} data - The data to encrypt.
 * @returns {Promise<string>} A promise that resolves to the JWE compact string.
 */
export const encryptCardNumber = async (data) => {
  try {
    // Fetch the PEM file for the public key
    const response = await fetch('/stip-sit-public-key.pem'); // Path to your PEM file
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const pemContent = await response.text();

    // Trim and extract PEM key
    const trimmedPem = pemContent.trim();
    const publicKeyStart = trimmedPem.indexOf('-----BEGIN PUBLIC KEY-----');
    const publicKeyEnd = trimmedPem.indexOf('-----END PUBLIC KEY-----');

    if (publicKeyStart === -1 || publicKeyEnd === -1) {
      throw new Error('Invalid PEM format: Could not find public key markers.');
    }

    const pemWithMarkers = trimmedPem.slice(
      publicKeyStart,
      publicKeyEnd + '-----END PUBLIC KEY-----'.length
    );

    // Convert PEM-formatted public key to Forge's RSA key object
    const publicKey = forge.pki.publicKeyFromPem(pemWithMarkers);

    // Generate a random Initialization Vector (IV) for AES-GCM
    const iv = forge.random.getBytesSync(12); // 12-byte IV for GCM

    // Generate a random AES-256 key
    const aesKey = forge.random.getBytesSync(32); // 32 bytes = 256 bits

    // Encrypt the AES key using RSA-OAEP with the public key
    const encryptedKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
      md: forge.md.sha512.create(), // Use SHA-512 for OAEP
      mgf1: forge.mgf.mgf1.create(forge.md.sha512.create()), // MGF1 with SHA-512
    });

    // Encrypt the data using AES-GCM
    const cipher = forge.cipher.createCipher('AES-GCM', aesKey);
    cipher.start({ iv, additionalData: '', tagLength: 128 });
    cipher.update(forge.util.createBuffer(data));
    cipher.finish();

    const ciphertext = cipher.output.getBytes();
    const tag = cipher.mode.tag.getBytes();

    // Base64-encode all parts
    const encodedHeader = forge.util.encode64(
      JSON.stringify({ enc: 'A256GCM', alg: 'RSA-OAEP-512' }) // Header matches the first example
    );
    const encodedEncryptedKey = forge.util.encode64(encryptedKey);
    const encodedIv = forge.util.encode64(iv);
    const encodedCiphertext = forge.util.encode64(ciphertext);
    const encodedTag = forge.util.encode64(tag);

    // Combine all parts into the compact JWE format
    const compactJWE = `${encodedHeader}.${encodedEncryptedKey}.${encodedIv}.${encodedCiphertext}.${encodedTag}`;

    return compactJWE; // Return the JWE string
  } catch (error) {
    console.error('Error encrypting data:', error);
    throw new Error('Failed to encrypt card number.');
  }
};

export default encryptCardNumber;
