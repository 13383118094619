// src/components/StandIn.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Grid,
  Button,
  Container,
  Tooltip,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { logMessage } from '../components/util/UtilApis'; // Import logMessage function



const StandIn = () => {
  const [isStandInActive, setIsStandInActive] = useState(false); // State for toggle switch

  const handleToggle = () => {
    setIsStandInActive((prev) => {
      const newState = !prev;
      logMessage('INFO', `Automatic Stand-In toggled to ${newState ? 'Active' : 'Inactive'}.`, 'StandIn');
      return newState;
    }); // Toggle the state
  };

  const handleSave = () => {
    try {
      logMessage('USER_AUDIT', `User saved Automatic Stand-In as ${isStandInActive ? 'enabled' : 'disabled'}.`, 'StandIn');
      alert(`Automatic Stand-In is now ${isStandInActive ? 'enabled' : 'disabled'}`);
    } catch (error) {
      logMessage('ERROR', `Error saving Automatic Stand-In state: ${error.message}`, 'StandIn');
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 5 }}>
      <Paper elevation={6} sx={{ padding: 4, borderRadius: '12px', backgroundColor: '#ffffff' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Automatic Stand-In Management
          </Typography>
          <Tooltip title="Manage your automatic stand-in settings">
            <IconButton>
              <InfoIcon color="action" />
            </IconButton>
          </Tooltip>
        </Box>

        <Typography variant="body1" gutterBottom>
          Current Status: <strong>{isStandInActive ? 'Active' : 'Inactive'}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary" mb={3}>
          Automatically manage your stand-in settings based on your preferences. This feature allows for seamless transitions when you are unavailable.
        </Typography>
        
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <FormControlLabel
            control={
              <Switch
                checked={isStandInActive}
                onChange={handleToggle}
                color="primary"
                size="large" // Use a larger switch for better visibility
              />
            }
            label="Enable Automatic Stand-In"
            labelPlacement="start"
            sx={{ typography: 'h6' }}
          />
        </Box>

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ padding: '12px 24px', borderRadius: '20px' }}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setIsStandInActive(false);
                logMessage('INFO', 'User reset Automatic Stand-In to Inactive.', 'StandIn');
              }} // Reset to inactive
              sx={{ padding: '12px 24px', borderRadius: '20px' }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default StandIn;
