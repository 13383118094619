import React, { Component } from 'react';
import forge from 'node-forge';
import PropTypes from 'prop-types';

class SignatureGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privateKeyPem: null,
    };
  }

  componentDidMount() {
    this.fetchPemFile();
  }

  // Fetch the PEM file when the component mounts
  fetchPemFile = async () => {
    try {
      const response = await fetch('/stip-sit-private-key.pem'); // Adjust to your actual PEM file location
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const pemContent = await response.text();

      const trimmedPem = pemContent.trim();

      // Find the index of the private key markers
      const privateKeyStart = trimmedPem.indexOf("-----BEGIN PRIVATE KEY-----");
      const privateKeyEnd = trimmedPem.indexOf("-----END PRIVATE KEY-----");

      // If both markers are found, slice out the private key portion
      if (privateKeyStart !== -1 && privateKeyEnd !== -1) {
        // Extract the base64-encoded private key without the markers
        const base64Key = trimmedPem.slice(
          privateKeyStart + "-----BEGIN PRIVATE KEY-----".length,
          privateKeyEnd
        ).trim();

        // Rebuild the full PEM content with markers around the base64 content
        const pemWithMarkers = `-----BEGIN PRIVATE KEY-----\n${base64Key}\n-----END PRIVATE KEY-----`;

        this.setState({ privateKeyPem: pemWithMarkers }); // Set the full PEM content with markers
      } else {
        console.error("Invalid PEM format: Could not find private key markers.");
      }
    } catch (error) {
      console.error('Error loading PEM file:', error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { privateKeyPem } = this.state;
    const { header, body, onSignatureGenerated } = this.props;

    if (privateKeyPem && header && body) {
      if (
        prevState.privateKeyPem !== privateKeyPem ||
        prevProps.header !== header ||
        prevProps.body !== body
      ) {
        // Combine header and body into a single object for signature generation
        const combinedPayload = { header, body };
        console.log("**********Combined Payload**************");
        console.log("Payload in React: ", JSON.stringify(combinedPayload));
        console.log("****************************************");

        // Normalize the JSON payload (remove whitespace differences)
        const payloadString = JSON.stringify(combinedPayload); // No spaces or indentation
        try {
          // Convert the private key to an RSA key object using node-forge
          const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);

          // Create a SHA-256 hash of the normalized payload string
          const md = forge.md.sha256.create();
          md.update(payloadString, 'utf8'); // Ensure UTF-8 encoding

          // Sign the hash using the private key (RSA with SHA-256)
          const generatedSignature = privateKey.sign(md);

          // Encode the signature in Base64
          const base64EncodedSignature = forge.util.encode64(generatedSignature);

          // Convert the Base64-encoded signature to a hexadecimal string
          const hexEncodedSignature = this.bytesToHex(
            forge.util.binary.raw.decode(base64EncodedSignature)
          );

          // Pass the signature to the parent component via the callback
          onSignatureGenerated(hexEncodedSignature);
        } catch (error) {
          console.error("Error generating signature:", error);
        }
      }
    }
  }

  // Utility function to convert bytes to a hexadecimal string
  bytesToHex = (bytes) => {
    return Array.from(bytes, (byte) => ('0' + (byte & 0xff).toString(16)).slice(-2)).join('');
  };

  render() {
    return null; // This component does not render anything
  }
}

// Define prop types for better type checking
SignatureGenerator.propTypes = {
  header: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  onSignatureGenerated: PropTypes.func.isRequired,
};

export default SignatureGenerator;
