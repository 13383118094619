import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { logMessage } from '../util/UtilApis';
import SignatureGenerator from './SignatureGenerator';
import { encryptCardNumber } from './CardNumberEncrypter';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 6,
  },
}));

const PositiveList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    effectiveDate: null,
    expiryDate: null,
    instrumentType: 'P1', // Static value for Positive
    instrumentStatus: '00', // Static value for Instrument Status
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [signature, setSignature] = useState('');
 const [encryptedCardNumber, setEncryptedCardNumber] = useState('');
  const [header, setHeader] = useState({});
  const [body, setBody] = useState({});

  // Encrypt card number and update state
    useEffect(() => {
      const encryptAndSetCardNumber = async () => {
        try {
          if (formData.cardNumber) {
            const encryptedValue = await encryptCardNumber(formData.cardNumber);
            setEncryptedCardNumber(encryptedValue);
            logMessage('INFO', 'Card number encrypted successfully.', 'NegativeList');
          } else {
            setEncryptedCardNumber('');
          }
        } catch (error) {
          logMessage('ERROR', `Failed to encrypt card number: ${error.message}`, 'NegativeList');
        }
      };
  
      encryptAndSetCardNumber();
    }, [formData.cardNumber]);

  useEffect(() => {
    const newHeader = {
      apiVersion: '3.0.0',
      srcSysId: 'nets', // Replace with actual source system ID
      srcSysRef: `${dayjs().format('YYYYMMDDHHmmssSSS')}1234567890123`, // Source system reference
      //pwd: 'pwd', // Added pwd field
      srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };

    const newBody = {
      transactionId: '123456789012', // Replace with actual transaction ID
      transactionTime: dayjs().format('YYYY-MM-DDTHH-mm-ss[Z]'), // ISO 8601 format
      institutionId: props.institutionId, // Pass institutionId from props
      cardNumber: encryptedCardNumber, // Encrypt this value with RSA-2048 if needed
      cardSequenceNumber: formData.cardSequenceNumber,
      authorizationType: 'P', // 'P' for Positive
      functionCode: '301', // '301' for Add operation
      taggedDataBuffer: generateTaggedDataBuffer(),
    };

    setHeader(newHeader);
    setBody(newBody);
  }, [formData]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateForm()) {
        if (!signature) {
          logMessage('WARNING', 'Signature has not been generated yet.', 'PositiveList');
          return false;
        }

        logMessage('INFO', 'Form validation passed. Preparing payload.', 'PositiveList');

        const payload = {
          header: header,
          body: body,
          trailer: {
            signature: signature, // Set the generated signature here
          },
        };

        logMessage('USER_AUDIT', 'Generated payload for form submission with signature.', 'PositiveList');
        console.log('Generated Payload:', payload);

        if (props.onSuccess && typeof props.onSuccess === 'function') {
          props.onSuccess('Card submitted successfully!');
        }

        return payload;
      } else {
        logMessage('WARNING', 'Form validation failed. Payload generation aborted.', 'PositiveList');
      }
      return false;
    },
    resetForm: () => {
      setFormData(initialFormData);
      setErrors({});
      logMessage('INFO', 'Form has been reset to initial state.', 'PositiveList');
    },
  }));

  const handleSignatureGenerated = (generatedSignature) => {
    setSignature(generatedSignature);
    logMessage('INFO', 'Signature has been generated and set in trailer.', 'PositiveList');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    logMessage('INFO', `Field '${name}' updated with value: ${value}`, 'PositiveList');
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
      logMessage('WARNING', 'Invalid card number entered.', 'PositiveList');
    } else if (!isValidLuhn(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number failed the Luhn check.';
      logMessage('WARNING', 'Card number failed Luhn check.', 'PositiveList');
    }

    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
      logMessage('WARNING', 'Invalid card sequence number entered.', 'PositiveList');
    }

    if (!formData.effectiveDate || !dayjs(formData.effectiveDate).isValid()) {
      validationErrors.effectiveDate = 'Please select a valid effective date.';
      logMessage('WARNING', 'Effective date is invalid or missing.', 'PositiveList');
    }

    if (!formData.expiryDate || !dayjs(formData.expiryDate).isValid()) {
      validationErrors.expiryDate = 'Please select a valid expiry date.';
      logMessage('WARNING', 'Expiry date is invalid or missing.', 'PositiveList');
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      logMessage('INFO', 'All fields validated successfully.', 'PositiveList');
    }

    return Object.keys(validationErrors).length === 0;
  };

  const isValidLuhn = (number) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  const generateTaggedDataBuffer = () => {
    const tags = [
      { tag: '01', value: formData.instrumentType, type: 'string' },
      { tag: '02', value: formData.instrumentStatus, type: 'number' },
      { tag: '04', value: dayjs(formData.effectiveDate).format('YYYYMMDD'), type: 'number' },
      { tag: '06', value: dayjs(formData.expiryDate).format('YYYYMM'), type: 'number' },
    ];

    return tags
      .map(({ tag, value, type }) => {
        const length = value.length.toString().padStart(3, '0');
        const formattedValue =
          type === 'number'
            ? value.padStart(parseInt(length, 10), '0')
            : value.padEnd(parseInt(length, 10), ' ');
        return `${tag}${length}${formattedValue}`;
      })
      .join('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Effective Date"
            views={['year', 'month', 'day']}
            value={formData.effectiveDate}
            onChange={(newValue) => setFormData({ ...formData, effectiveDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.effectiveDate} helperText={errors.effectiveDate} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            variant="outlined"
            value="P1" // Static value for Positive
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <SignatureGenerator
          header={header}
          body={body}
          onSignatureGenerated={handleSignatureGenerated}
        />
      </Grid>
    </LocalizationProvider>
  );
});

export default PositiveList;
