import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SecurityIcon from '@mui/icons-material/Security';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { Auth } from 'aws-amplify'; // Add this import
import { useUser } from '../../UserContext'; // Import UserContext to access user data globally
import { logMessage } from '../util/UtilApis'; // Import logMessage function
import netsLogo from './nets-logo.png';
import './Navbar.css';

function Navbar() {
    const navigate = useNavigate();
    const { user, clearCache } = useUser(); // Access user data and cache-clearing function from context
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // Handle user login by redirecting to Cognito-hosted login page
    const handleLogin = async () => {
        logMessage('USER_AUDIT', 'User initiated login.', 'Navbar');
        await Auth.federatedSignIn();
    };

    // Handle logout: clear cache, reset user data, and redirect to home
    const handleLogout = async () => {
        logMessage('USER_AUDIT', 'User initiated logout.', 'Navbar');
        try {
            await Auth.signOut();
            clearCache(); // Clears cache globally
            navigate('/'); // Redirect to home after logout
            logMessage('INFO', 'User logged out successfully.', 'Navbar');
        } catch (error) {
            logMessage('ERROR', `Error during logout: ${error.message}`, 'Navbar');
        }
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        logMessage('INFO', 'User opened the account menu.', 'Navbar');
    };

    const handleClose = () => {
        setAnchorEl(null);
        logMessage('INFO', 'User closed the account menu.', 'Navbar');
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
        logMessage(open ? 'INFO' : 'INFO', `User ${open ? 'opened' : 'closed'} the drawer.`, 'Navbar');
    };

    const handleMenuItemClick = () => {
        setIsDrawerOpen(false);
        logMessage('INFO', 'User selected a menu item.', 'Navbar');
    };

    const downloadParametersUploadForm = () => {
        const link = document.createElement('a');
        link.href = 'Authorization_Limits_Update_Form.pdf';
        link.download = 'Authorization_Limits_Update_Form.pdf';
        link.click();
        logMessage('USER_AUDIT', 'User downloaded the Authorization Limit Update Form.', 'Navbar');
    };

    const drawerList = () => (
        <div
            role="presentation"
            style={{
                width: '280px',
                backgroundColor: '#f5f6fa',
                padding: '20px',
                borderRadius: '12px',
                boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
            }}
        >
            <List>
                <ListItem
                    button
                    component={NavLink}
                    to="/stand-in"
                    onClick={handleMenuItemClick}
                    sx={{
                        borderRadius: '10px',
                        marginBottom: '12px',
                        padding: '16px',
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e8f5e9',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    <ListItemIcon>
                        <SecurityIcon style={{ color: '#1e88e5', fontSize: '28px' }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Automatic Stand-In on/off"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#4a4a4a',
                        }}
                    />
                </ListItem>

                <ListItem
                    button
                    component={NavLink}
                    to="/new-card"
                    onClick={handleMenuItemClick}
                    sx={{
                        borderRadius: '10px',
                        marginBottom: '12px',
                        padding: '16px',
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e8f5e9',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    <ListItemIcon>
                        <CreditCardIcon style={{ color: '#1976D2', fontSize: '28px' }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Add Card"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#4a4a4a',
                        }}
                    />
                </ListItem>

                <ListItem
                    button
                    component={NavLink}
                    to="/existing-card"
                    onClick={handleMenuItemClick}
                    sx={{
                        borderRadius: '10px',
                        marginBottom: '12px',
                        padding: '16px',
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e8f5e9',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    <ListItemIcon>
                        <CardMembershipIcon style={{ color: '#1976D2', fontSize: '28px' }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Update Card"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#4a4a4a',
                        }}
                    />
                </ListItem>

                <ListItem
                    button
                    onClick={downloadParametersUploadForm}
                    sx={{
                        borderRadius: '10px',
                        marginBottom: '12px',
                        padding: '16px',
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e8f5e9',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon style={{ color: '#1976D2', fontSize: '28px' }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Authorization Limit Update Form"
                        primaryTypographyProps={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#4a4a4a',
                        }}
                    />
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: '#ffffff', color: '#000000' }}>
                <Container>
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {user && (
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Typography variant="h6" component="div" style={{ marginLeft: '16px' }}>
                                <img src={netsLogo} alt="Nets Logo" style={{ height: '40px', marginRight: '16px' }} />
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {user ? (
                                <>
                                    <IconButton edge="end" color="inherit" onClick={handleMenu}>
                                        <AccountCircle />
                                    </IconButton>
                                    <Typography variant="body1" style={{ margin: '0 8px' }}>{user.attributes.name || user.userId}</Typography>
                                    <IconButton edge="end" onClick={handleMenu} color="inherit">
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        keepMounted
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem component={NavLink} to="/profile" onClick={handleClose}>Profile</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <Button color="inherit" onClick={handleLogin} style={{ fontWeight: 'bold' }}>
                                    Login
                                </Button>
                            )}
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                {drawerList()}
            </Drawer>
        </>
    );
}

export default Navbar;
