import axios from 'axios';
import { config } from '../Constants'; // Import the config from Constants.js

// Store token and expiration time
export const setAccessToken = (token, expiresIn) => {
  const expirationTime = Date.now() + expiresIn * 1000; // Convert seconds to milliseconds
  console.log('Setting new token and expiration time:', token, expirationTime);
  localStorage.setItem('accessToken', token);
  localStorage.setItem('tokenExpiration', expirationTime);
};

// Get token from localStorage
export const getAccessToken = () => {
  const token = localStorage.getItem('accessToken');
  console.log('Access Token Retrieved:', token);  // Log the token retrieved from localStorage
  return token;
};

// Check if the token is expired
export const isTokenExpired = () => {
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  console.log('Token Expiration Time:', tokenExpiration, 'Current Time:', Date.now());
  const expired = Date.now() > tokenExpiration; // Compare current time with stored expiration time
  console.log('Is token expired?', expired);  // Log whether the token is expired
  return expired;
};

// Refresh the access token using client_credentials flow (No refresh token API)
export const refreshAccessToken = async () => {
  try {
    console.log('Token expired, refreshing using client credentials...');
    
    const response = await axios.post(`${config.url.STIP_API_URL}/oauth2/token`, new URLSearchParams({
      grant_type: 'client_credentials',
      client_id: config.auth.CLIENT_ID, // Use CLIENT_ID from config
      client_secret: config.auth.CLIENT_SECRET // Use CLIENT_SECRET from config
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    const newToken = response.data.access_token;
    const expiresIn = response.data.expires_in;
    console.log('New Token:', newToken, 'Expires In:', expiresIn);
    setAccessToken(newToken, expiresIn); // Store the new token and expiration time
    return newToken;
  } catch (error) {
    console.error('Failed to refresh access token', error);
    throw error;
  }
};
