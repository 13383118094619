import axios from 'axios';
import { config } from '../../Constants'; // Import the config from Constants.js
import { getAccessToken, isTokenExpired, refreshAccessToken } from '../../api/authService'; // Import token functions

// Create axios instance using the STIP_API_URL from Constants.js
const instance = axios.create({
  baseURL: config.url.STIP_API_URL, // Use the STIP_API_URL from constants.js
  headers: {
    'Content-Type': 'application/json', // Ensure content type is application/json
  },
});

// Axios interceptor to automatically attach the token and refresh it if expired
instance.interceptors.request.use(
  async (config) => {
    let token = getAccessToken();
    console.log('Retrieved Token:', token); // Log the token retrieved from localStorage

    if (isTokenExpired()) {
      console.log('Token expired, refreshing...');
      token = await refreshAccessToken(); // Refresh the token using client_credentials flow if expired
    }

    if (token) {
      console.log('Adding Authorization header:', `Bearer ${token}`); // Log the token being added to the header
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    } else {
      console.log('No token found, authentication required.');
    }

    return config;
  },
  (error) => {
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

// Handle responses
instance.interceptors.response.use(
  (response) => {
    console.log('Response Received:', response);
    return response;
  },
  async (error) => {
    console.error('Error Response:', error.response); // Log the error response

    if (error.response && error.response.status === 401) {
      console.error('Token expired or invalid. Please log in again.');
    }

    return Promise.reject(error);
  }
);

// Function to handle form submission for /api/cards/update
const submitForm = async (formData) => {
  try {
    const url = `${config.url.STIP_API_URL}/stipsit/v1/submitcardinfo`;
    //const url = `${config.url.STIP_API_URL}/api/cards/update`;
    console.log('Submitting form data:', formData); // Log the form data being sent

    const response = await instance.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Form submitted successfully:', response);
    return response;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};

// Function to handle card inquiry for /api/cards/inquiry
const inquireCard = async (inquiryData) => {
  try {
    const url = `${config.url.STIP_API_URL}/stipsit/v1/inquirecardinfo`;
    //const url = `${config.url.STIP_API_URL}/api/cards/inquiry`;
    console.log('Sending inquiry data:', inquiryData); // Log the inquiry data being sent

    const response = await instance.post(url, inquiryData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Card inquiry successful:', response);
    return response;
  } catch (error) {
    console.error('Error during card inquiry:', error);
    throw error;
  }
};

// Export cardsApi object containing submitForm and inquireCard functions
export const cardsApi = {
  submitForm, // Export submitForm for /api/cards/update
  inquireCard, // Export inquireCard for /api/cards/inquiry
};
