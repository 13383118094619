import axios from 'axios';
import { config } from '../../Constants'; // Adjust the path as per your project structure

/**
 * Logs a message to the API with the specified log level.
 * @param {string} level - Log level (e.g., INFO, WARN, ERROR, USER_AUDIT).
 * @param {string} message - Message to log.
 * @param {string} componentName - Name of the component sending the log.
 * @returns {Promise} - A promise that resolves when the log is sent.
 */
export const logMessage = async (level, message, componentName) => {
  try {
   

    const payload = `${level}]:::::> [${componentName}  --->${message}`;
    
    //const response = await axios.post(config.url.STIP_LAMBDA_LOGS_API_URL, payload, {
    //  headers: {
    //    'Content-Type': 'application/json',
    //  },
    //});

    //console.log(payload);
    //console.log(`Log sent successfully: [${componentName} --->${level}]`, response.data);
  } catch (error) {
    console.error(`Failed to send log: [${componentName} --->${level}]`, error);
  }
};
