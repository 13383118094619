import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CheckIcon from '@mui/icons-material/Check';
import { logMessage } from '../util/UtilApis';
import { encryptCardNumber } from './CardNumberEncrypter';
import SignatureGenerator from './SignatureGenerator';
import SignatureVerifier from './SignatureVerifier';
import dayjs from 'dayjs';
import { cardsApi } from '../misc/CardsApi';
import NegativeList from './NegativeList'; // Import NegativeList component
import PositiveList from './PositiveList'; // Import PositiveList component

// Styled Button
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007BFF',
  color: '#fff',
  padding: '12px 24px',
  borderRadius: 8,
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '16px',
  boxShadow: '0px 4px 10px rgba(0, 123, 255, 0.3)',
  '&:hover': {
    backgroundColor: '#0056b3',
    boxShadow: '0px 6px 14px rgba(0, 123, 255, 0.4)',
  },
}));

// Custom TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 10,
    '& fieldset': {
      borderColor: grey[400],
    },
    '&:hover fieldset': {
      borderColor: '#007BFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007BFF',
    },
  },
}));

// Custom Card
const CustomCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 12,
  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
  margin: 'auto',
  width: '1000px',
}));

const ExistingCard = () => {
  const [formData, setFormData] = useState({
    cardNumber: '',
    cardSequenceNumber: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState({});
  const [body, setBody] = useState({});
  const [signature, setSignature] = useState('');
  const [encryptedCardNumber, setEncryptedCardNumber] = useState('');
  const [responseHeader, setResponseHeader] = useState(null);
  const [responseSignature, setResponseSignature] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [authorizationType, setAuthorizationType] = useState(null); // To track the type of authorization

  // Encrypt card number when it changes
  useEffect(() => {
    const encryptAndSetCardNumber = async () => {
      if (formData.cardNumber) {
        try {
          const encryptedValue = await encryptCardNumber(formData.cardNumber);
          setEncryptedCardNumber(encryptedValue);
          logMessage('INFO', 'Card number encrypted successfully.', 'ExistingCard');
        } catch (error) {
          logMessage('ERROR', `Encryption failed: ${error.message}`, 'ExistingCard');
        }
      } else {
        setEncryptedCardNumber('');
      }
    };

    encryptAndSetCardNumber();
  }, [formData.cardNumber]);

  // Update header and body when encryptedCardNumber or other data changes
  useEffect(() => {
    const newHeader = {
      apiVersion: '3.0.0',
      srcSysId: 'NETS123456',
      srcSysRef: `${dayjs().format('YYYYMMDDHHmmssSSS')}1234567890123`,
      pwd: null,
      srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };

    const newBody = {
      transactionId: '123456789012',
      transactionTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      institutionId: 'nets',
      cardNumber: encryptedCardNumber,
      cardSequenceNumber: formData.cardSequenceNumber,
      authorizationType: 'N',
      functionCode: '301',
    };

    setHeader(newHeader);
    setBody(newBody);
  }, [encryptedCardNumber, formData.cardSequenceNumber]);

  const validateForm = () => {
    const validationErrors = {};

    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits.';
      logMessage('WARNING', 'Invalid card number.', 'ExistingCard');
    }

    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
      logMessage('WARNING', 'Invalid card sequence number.', 'ExistingCard');
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleInquire = async (event) => {
    event.preventDefault();
    if (!validateForm() || !signature) {
      setErrorMessage('Form validation failed or signature not generated.');
      return;
    }

    setLoading(true);

    const payload = {
      header,
      body,
      trailer: { signature },
    };

    try {
      const response = await cardsApi.inquireCard(payload);
      const { header: responseHeader, trailer, body: responseBody } = response.data;

      setResponseHeader(responseHeader);
      setResponseSignature(trailer.signature);
      setAuthorizationType(responseBody.authorizationType); // Update the authorization type

      logMessage('INFO', 'Card inquiry successful.', 'ExistingCard');
    } catch (error) {
      logMessage('ERROR', `Inquiry failed: ${error.message}`, 'ExistingCard');
      setErrorMessage('Inquiry failed.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignatureGenerated = (generatedSignature) => {
    setSignature(generatedSignature);
    logMessage('INFO', 'Signature generated successfully.', 'ExistingCard');
  };

  const handleVerificationResult = (isValid) => {
    if (isValid) {
      setSuccessMessage('Response verified successfully.');
    } else {
      setErrorMessage('Invalid response signature.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Conditional rendering based on authorizationType
  if (authorizationType === 'N') {
    return <NegativeList />;
  } else if (authorizationType === 'P') {
    return <PositiveList />;
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomCard>
        <CardContent>
          <Typography variant="h5" textAlign="center" marginBottom={2}>
            Existing Card Inquiry
          </Typography>
          <Box component="form" onSubmit={handleInquire}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Card Number"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  error={!!errors.cardNumber}
                  helperText={errors.cardNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CreditCardIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Card Sequence Number"
                  name="cardSequenceNumber"
                  value={formData.cardSequenceNumber}
                  onChange={handleChange}
                  error={!!errors.cardSequenceNumber}
                  helperText={errors.cardSequenceNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SignatureGenerator
                  header={header}
                  body={body}
                  onSignatureGenerated={handleSignatureGenerated}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <StyledButton type="submit">
                  <CheckIcon sx={{ mr: 1 }} />
                  Inquire
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </CustomCard>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default ExistingCard;
