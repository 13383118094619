import React, { Component } from 'react';
import forge from 'node-forge';
import PropTypes from 'prop-types';

class SignatureVerifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicKeyPem: null,
    };
  }

  componentDidMount() {
    this.fetchPemFile();
  }

  fetchPemFile = async () => {
    try {
      const response = await fetch('/stip-sit-public-key.pem'); // Adjust path
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const pemContent = await response.text();
      this.setState({ publicKeyPem: pemContent.trim() });
    } catch (error) {
      console.error('Error loading PEM file:', error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { publicKeyPem } = this.state;
    const { header, signature, onVerificationResult } = this.props;

    if (publicKeyPem && header && signature) {
      if (
        prevState.publicKeyPem !== publicKeyPem ||
        prevProps.header !== header ||
        prevProps.signature !== signature
      ) {
        this.verifySignature(header, signature, publicKeyPem, onVerificationResult);
      }
    }
  }

  verifySignature = (header, signature, publicKeyPem, onVerificationResult) => {
    try {
      const payloadString =
        typeof header === 'string' ? header : JSON.stringify(header);

      console.log("********** Verifying Payload **************");
      console.log("Payload for Verification: ", payloadString);
      console.log("Signature (Hex): ", signature);
      console.log("*******************************************");

      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
      const signatureBytes = this.convertHexToBase64DecodedBytes(signature);

      console.log("Decoded Signature Length:", signatureBytes.length);

      const md = forge.md.sha256.create();
      md.update(payloadString, 'utf8'); // Ensure UTF-8 encoding

      const isValid = publicKey.verify(md.digest().bytes(), signatureBytes);

      console.log("Signature verification result:", isValid);

      onVerificationResult(isValid);
    } catch (error) {
      console.error("Error verifying signature:", error);
      onVerificationResult(false);
    }
  };

  hexToBytes = (hex) => {
    const bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return bytes;
  };

  convertHexToBase64DecodedBytes = (hex) => {
    const rawBytes = this.hexToBytes(hex);
    const byteString = String.fromCharCode(...rawBytes);
    return forge.util.decode64(byteString);
  };

  render() {
    return null;
  }
}

SignatureVerifier.propTypes = {
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  signature: PropTypes.string.isRequired,
  onVerificationResult: PropTypes.func.isRequired,
};

export default SignatureVerifier;
