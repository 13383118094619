const prod = {
  url: {
    KEYCLOAK_BASE_URL: "https://keycloak.herokuapp.com",
    API_BASE_URL: 'https://myapp.herokuapp.com',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x',
    STIP_API_URL: 'https://mbejz1t2p5.execute-api.ap-southeast-1.amazonaws.com/sit_deploy',
    //STIP_API_URL: 'https://xkyb9xdzza.execute-api.ap-southeast-1.amazonaws.com/stipsitportal/stipsitportal/submit', // New STIP API URL for production
    STIP_LAMBDA_LOGS_API_URL: 'https://azb9oy3jrb.execute-api.ap-southeast-1.amazonaws.com/dev/logger/submit'
  }, 

  auth: {
    CLIENT_ID: '2kbk1kqch6m6f7d6pdihal0ess', // Replace with your OAuth client ID
    CLIENT_SECRET: '1qf3ncqho9r2lblol4h96hbm1222394rojgdngvctgkb4dc3tjjv', // Replace with your OAuth client secret
  },
  tokenSettings: {
    TOKEN_REFRESH_INTERVAL: 55 * 60 * 1000, // Refresh token every 55 minutes
  },
}

const dev = {
  url: {
    KEYCLOAK_BASE_URL: "http://localhost:8080",
    API_BASE_URL: 'http://localhost:9090',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://api.dicebear.com/6.x',
    STIP_API_URL: 'http://localhost:9090', // New STIP API URL for development
    //STIP_API_URL: 'https://xkyb9xdzza-vpce-0fcabcd0bd2ba8fff.execute-api.ap-southeast-1.vpce.amazonaws.com/stipsitportal/stipsitportal/submit',
    //STIP_API_URL: 'https://mbejz1t2p5.execute-api.ap-southeast-1.amazonaws.com/sit_deploy',
    STIP_LAMBDA_LOGS_API_URL: 'https://azb9oy3jrb.execute-api.ap-southeast-1.amazonaws.com/dev/logger/submit'
  }, 
  auth: {
    CLIENT_ID: '2kbk1kqch6m6f7d6pdihal0ess', // Replace with your OAuth client ID
    CLIENT_SECRET: '1qf3ncqho9r2lblol4h96hbm1222394rojgdngvctgkb4dc3tjjv', // Replace with your OAuth client secret
  },
  tokenSettings: {
    TOKEN_REFRESH_INTERVAL: 55 * 60 * 1000, // Refresh token every 55 minutes
  },
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
