import { Container, CircularProgress, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { logMessage } from '../util/UtilApis'; // Import logMessage function

function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const sessionCheckInterval = 600000; // 10 minutes in milliseconds for session polling

  const handleLogout = () => {
    setIsAuthenticated(false);
    logMessage('USER_AUDIT', 'User logged out.', 'Home');
    Auth.federatedSignIn(); // Redirect to Cognito login
  };

  const checkSession = async () => {
    try {
      const session = await Auth.currentSession();
      const now = Math.floor(Date.now() / 1000);
      const expiration = session.getAccessToken().getExpiration();

      // Check if the token is still valid
      if (now >= expiration) {
        throw new Error('Session expired');
      }

      setIsAuthenticated(true);
      logMessage('INFO', 'User session is valid.', 'Home');
    } catch (error) {
      logMessage('ERROR', `Session check failed: ${error.message}`, 'Home');
      handleLogout(); // If session is invalid, log out immediately
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkSession(); // Initial session check on component mount

    // Polling: Perform session check every 1 second
    const intervalId = setInterval(checkSession, sessionCheckInterval);

    // Listen for Cognito authentication events, especially for signOut events
    const authListener = Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signOut') {
        logMessage('USER_AUDIT', 'User signed out via Cognito event.', 'Home');
        handleLogout(); // Log out immediately on signOut event
      }
    });

    // Clean up the interval and event listener on component unmount
    return () => {
      clearInterval(intervalId);
      Hub.remove('auth', authListener);
    };
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="80vh"
          textAlign="center"
          sx={{ border: '1px dashed #053ca3', borderRadius: '8px', padding: 2, backgroundColor: '#f5f6fa' }}
        >
          <CircularProgress />
          <Typography variant="h6" color="textSecondary" mt={2}>
            Checking session...
          </Typography>
        </Box>
      ) : isAuthenticated ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="80vh"
          textAlign="center"
          sx={{ border: '1px dashed #053ca3', borderRadius: '8px', padding: 2, backgroundColor: '#f5f6fa' }}
        >
          <Typography variant="h4" color="primary" gutterBottom>
            Welcome to the Card Management Portal
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Manage your card details, authorization limits, and more.
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="80vh"
          textAlign="center"
          sx={{ border: '1px dashed #053ca3', borderRadius: '8px', padding: 2, backgroundColor: '#f5f6fa' }}
        >
          <Typography variant="h6" color="textSecondary">
            Loading...
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default Home;
