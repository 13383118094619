import React, { useState, useRef, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Container,
  Card,
  CardContent,
  Box,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import NegativeList from './NegativeList';
import PositiveList from './PositiveList';
import VIPList from './VIPList';
import { styled } from '@mui/system';
import { Auth } from 'aws-amplify';
import { cardsApi } from '../misc/CardsApi'; // Adjust the import path
import { logMessage } from '../util/UtilApis'; // Import logMessage utility
import SignatureVerifier from './SignatureVerifier'; // Import SignatureVerifier component

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007BFF',
  borderRadius: 6,
  color: 'white',
  height: 48,
  padding: '0 30px',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'uppercase',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#0056b3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledHeader = styled('div')(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#003366',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  textTransform: 'uppercase',
  letterSpacing: '2px',
}));

const GlassCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(4),
  width: '1000px',
  margin: 'auto',
}));

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  animation: 'fadeIn 1.5s ease-in-out',
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
}));

const CardForm = () => {
  const [selectedOption, setSelectedOption] = useState('Negative'); // Default option
  const [bankName, setBankName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseHeader, setResponseHeader] = useState(null);
  const [responseSignature, setResponseSignature] = useState(null);

  const negativeListRef = useRef();
  const positiveListRef = useRef();
  const vipListRef = useRef();

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = user.attributes;

        logMessage('INFO', 'Fetching user attributes', 'CardForm');

        if (attributes['custom:bank_name']) {
          const bankNameValue = attributes['custom:bank_name'].toLowerCase();
          setBankName(bankNameValue);
          logMessage('USER_AUDIT', `Bank name set to ${bankNameValue}`, 'CardForm');

          switch (bankNameValue) {
            case 'dbsc':
              setSelectedOption('Negative');
              break;
            case 'uobc':
              setSelectedOption('VIP');
              break;
            case 'ocbc':
              setSelectedOption('Positive');
              break;
            default:
              setSelectedOption('Negative'); // Default to Negative
          }
        }
      } catch (error) {
        logMessage('ERROR', `Error fetching user attributes: ${error.message}`, 'CardForm');
        console.error('Error fetching user attributes:', error);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleOptionChange = (event, newOption) => {
    setSelectedOption(newOption);
    logMessage('USER_AUDIT', `Tab changed to ${newOption}`, 'CardForm');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = null;

    if (selectedOption === 'Negative') {
      payload = negativeListRef.current?.submitForm();
    } else if (selectedOption === 'Positive') {
      payload = positiveListRef.current?.submitForm();
    } else if (selectedOption === 'VIP') {
      payload = vipListRef.current?.submitForm();
    }

    if (payload) {
      try {
        logMessage('INFO', `Submitting form for ${selectedOption}`, 'CardForm');
        const response = await cardsApi.submitForm(payload);
        console.log('API Response:', response.data);

        const { header, trailer } = response.data;
        setResponseHeader(header);
        setResponseSignature(trailer.signature);

        handleTokenVerification(header, trailer.signature);
      } catch (error) {
        logMessage('ERROR', `Error during form submission: ${error.message}`, 'CardForm');
        console.error('Error during form submission:', error);
        setErrorMessage('An error occurred while submitting the form.');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleTokenVerification = (header, signature) => {
    setTimeout(() => {
      if (header && signature) {
        return (
          <SignatureVerifier
            header={header}
            signature={signature}
            onVerificationResult={(isValid) => {
              if (isValid) {
                const parsedHeader = typeof header === 'string' ? JSON.parse(header) : header;
                setSuccessMessage(parsedHeader.resMsg);
                logMessage('USER_AUDIT', 'Form submission successful', 'CardForm');
              } else {
                setErrorMessage('Invalid response signature. Please contact support.');
              }
            }}
          />
        );
      }
    }, 1000);
  };

  const handleVerificationResult = (isValid, header) => {
    if (isValid) {
      logMessage('INFO', 'Response signature verified successfully', 'CardForm');
      const parsedHeader = typeof header === 'string' ? JSON.parse(header) : header;
      setSuccessMessage(parsedHeader.resMsg || 'Card submitted successfully!');
      resetFormFields();
    } else {
      logMessage('ERROR', 'Invalid response signature', 'CardForm');
      setErrorMessage('Invalid response signature. Please contact support.');
    }
  };

  const resetFormFields = () => {
    negativeListRef.current?.resetForm();
    positiveListRef.current?.resetForm();
    vipListRef.current?.resetForm();
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <LoaderContainer>
          <CircularProgress color="inherit" size={80} thickness={4} />
          <Typography variant="h6" mt={2} color="inherit" fontWeight="bold">
            Processing your request, please wait...
          </Typography>
          <Typography variant="subtitle1" mt={1} color="inherit">
            This may take a few moments.
          </Typography>
        </LoaderContainer>
      </Backdrop>
      <GlassCard elevation={3}>
        <CardContent>
          <StyledHeader>Card Information Update</StyledHeader>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
            <Tabs value={selectedOption} onChange={handleOptionChange} variant="fullWidth" centered disabled={loading}>
              {bankName === 'dbsc' && <Tab label="Negative List" value="Negative" />}
              {bankName === 'uobc' && <Tab label="VIP List" value="VIP" />}
              {bankName === 'uobc' && <Tab label="Negative List" value="Negative" />}
              {bankName === 'ocbc' && <Tab label="Positive List" value="Positive" />}
              {bankName === 'ocbc' && <Tab label="VIP List" value="VIP" />}
            </Tabs>
          </Box>
          <form onSubmit={handleSubmit} disabled={loading}>
            {selectedOption === 'Negative' && <NegativeList ref={negativeListRef} institutionId={bankName} disabled={loading} />}
            {selectedOption === 'Positive' && <PositiveList ref={positiveListRef} institutionId={bankName} disabled={loading} />}
            {selectedOption === 'VIP' && <VIPList ref={vipListRef} institutionId={bankName} disabled={loading} />}
            <Box textAlign="center" sx={{ mt: 4, position: 'relative' }}>
              <StyledSubmitButton
                variant="contained"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: 'white', position: 'absolute' }} />
                ) : (
                  <><CheckIcon sx={{ mr: 1 }} />Submit</>
                )}
              </StyledSubmitButton>
            </Box>
          </form>
          {responseHeader && responseSignature && (
            <SignatureVerifier
              header={responseHeader}
              signature={responseSignature}
              onVerificationResult={(isValid) => handleVerificationResult(isValid, responseHeader)}
            />
          )}
          <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
            <Alert severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
            <Alert severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        </CardContent>
      </GlassCard>
    </Container>
  );
};

export default CardForm;
