import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { logMessage } from '../util/UtilApis';
import SignatureGenerator from './SignatureGenerator';
import { encryptCardNumber } from './CardNumberEncrypter';

const VIPList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    effectiveDate: null,
    expiryDate: null,
    dailyAmountLimit: '',
    transactionAmountLimit: '',
    dailyCountLimit: '',
    transactionLimitDate: null,
    instrumentType: 'P2', // Static value for VIP
    instrumentStatus: '01', // Static value
    limitName: 'TTL_LMT', // Static value
    currencyCode: '702', // Static value
    limitPeriod: '01', // Static value
    additionalPeriodData: '001', // Static value
    bufferZone: '00000000', // Static value
    counterName: 'TTL_CNT', // Static value
    limitCounter: '000', // Static value
    counterPeriod: '01', // Static value
    additionalCounterPeriodData: '001', // Static value
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [signature, setSignature] = useState('');
  const [encryptedCardNumber, setEncryptedCardNumber] = useState('');
  const [header, setHeader] = useState({});
  const [body, setBody] = useState({});

// Encrypt card number and update state
    useEffect(() => {
      const encryptAndSetCardNumber = async () => {
        try {
          if (formData.cardNumber) {
            const encryptedValue = await encryptCardNumber(formData.cardNumber);
            setEncryptedCardNumber(encryptedValue);
            logMessage('INFO', 'Card number encrypted successfully.', 'NegativeList');
          } else {
            setEncryptedCardNumber('');
          }
        } catch (error) {
          logMessage('ERROR', `Failed to encrypt card number: ${error.message}`, 'NegativeList');
        }
      };
  
      encryptAndSetCardNumber();
    }, [formData.cardNumber]);

  useEffect(() => {
    const newHeader = {
      apiVersion: '3.0.0',
      srcSysId: 'nets', // Replace with actual source system ID
      srcSysRef: `${dayjs().format('YYYYMMDDHHmmssSSS')}1234567890123`, // Source system reference
      //pwd: 'pwd', // Added pwd field
      srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };

    const newBody = {
      transactionId: '123456789012', // Replace with actual transaction ID
      transactionTime: dayjs().format('YYYY-MM-DDTHH-mm-ss[Z]'), // ISO 8601 format
      institutionId: props.institutionId, // Pass institutionId from props
      cardNumber: encryptedCardNumber, // Encrypt this value with RSA-2048 if needed
      cardSequenceNumber: formData.cardSequenceNumber,
      authorizationType: 'P', // 'P' for Positive/VIP
      functionCode: '301', // '301' for Add operation
      taggedDataBuffer: generateTaggedDataBuffer(),
    };

    setHeader(newHeader);
    setBody(newBody);
  }, [formData]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateForm()) {
        if (!signature) {
          logMessage('WARNING', 'Signature has not been generated yet.', 'VIPList');
          return false;
        }

        logMessage('INFO', 'Form validation passed. Preparing payload.', 'VIPList');

        const payload = {
          header: header,
          body: body,
          trailer: {
            signature: signature, // Set the generated signature here
          },
        };

        logMessage('USER_AUDIT', 'Generated payload for form submission with signature.', 'VIPList');
        console.log('Generated Payload:', payload);

        if (props.onSuccess && typeof props.onSuccess === 'function') {
          props.onSuccess('Card submitted successfully!');
        }

        return payload;
      } else {
        logMessage('WARNING', 'Form validation failed. Payload generation aborted.', 'VIPList');
      }
      return false;
    },
    resetForm: () => {
      setFormData(initialFormData);
      setErrors({});
      logMessage('INFO', 'Form has been reset to initial state.', 'VIPList');
    },
  }));

  const handleSignatureGenerated = (generatedSignature) => {
    setSignature(generatedSignature);
    logMessage('INFO', 'Signature has been generated and set in trailer.', 'VIPList');
  };

  const validateForm = () => {
    const validationErrors = {};

    // Card Number validation
    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
      logMessage('WARNING', 'Invalid card number entered.', 'VIPList');
    } else if (!isValidLuhn(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number failed the Luhn check.';
      logMessage('WARNING', 'Card number failed Luhn check.', 'VIPList');
    }

    // Card Sequence Number validation
    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
      logMessage('WARNING', 'Invalid card sequence number entered.', 'VIPList');
    }

    // Effective Date validation
    if (!formData.effectiveDate || !dayjs(formData.effectiveDate).isValid()) {
      validationErrors.effectiveDate = 'Effective date is required.';
      logMessage('WARNING', 'Effective date is invalid or missing.', 'VIPList');
    }

    // Expiry Date validation
    if (!formData.expiryDate || !dayjs(formData.expiryDate).isValid()) {
      validationErrors.expiryDate = 'Expiry date is required.';
      logMessage('WARNING', 'Expiry date is invalid or missing.', 'VIPList');
    }

    // Daily Amount Limit validation
    if (formData.dailyAmountLimit <= 0 || formData.dailyAmountLimit > 500) {
      validationErrors.dailyAmountLimit = 'Daily amount limit must be between 1 and 500.';
      logMessage('WARNING', 'Invalid daily amount limit entered.', 'VIPList');
    }

    // Transaction Amount Limit validation
    if (formData.transactionAmountLimit <= 0 || formData.transactionAmountLimit > 500) {
      validationErrors.transactionAmountLimit = 'Transaction amount limit must be between 1 and 500.';
      logMessage('WARNING', 'Invalid transaction amount limit entered.', 'VIPList');
    }

    // Daily Count Limit validation
    if (formData.dailyCountLimit <= 0 || formData.dailyCountLimit > 999) {
      validationErrors.dailyCountLimit = 'Daily count limit must be between 1 and 999.';
      logMessage('WARNING', 'Invalid daily count limit entered.', 'VIPList');
    }

    // Transaction Limit Date validation
    if (!formData.transactionLimitDate || !dayjs(formData.transactionLimitDate).isValid()) {
      validationErrors.transactionLimitDate = 'Transaction limit expiration date is required.';
      logMessage('WARNING', 'Transaction limit date is invalid or missing.', 'VIPList');
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      logMessage('INFO', 'All fields validated successfully.', 'VIPList');
    }

    // Return true if no errors, otherwise return false
    return Object.keys(validationErrors).length === 0;
  };

  const isValidLuhn = (number) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    logMessage('INFO', `Field '${name}' updated with value: ${value}`, 'VIPList');
  };

  const generateTaggedDataBuffer = () => {
    const tags = [
      { tag: '01', value: formData.instrumentType, type: 'string' },
      { tag: '02', value: formData.instrumentStatus, type: 'number' },
      { tag: '04', value: dayjs(formData.effectiveDate).format('YYYYMMDD'), type: 'number' },
      { tag: '06', value: dayjs(formData.expiryDate).format('YYYYMM'), type: 'number' },
      { tag: '29', value: formData.limitName.padEnd(16, ' '), type: 'string' },
      { tag: '30', value: formData.dailyAmountLimit.toString().padStart(12, '0'), type: 'number' },
      { tag: '31', value: formData.currencyCode, type: 'number' },
      { tag: '32', value: formData.limitPeriod, type: 'number' },
      { tag: '33', value: formData.additionalPeriodData, type: 'number' },
      { tag: '59', value: formData.bufferZone, type: 'number' },
      { tag: '34', value: formData.counterName.padEnd(16, ' '), type: 'string' },
      { tag: '35', value: formData.dailyCountLimit.toString().padStart(12, '0'), type: 'number' },
      { tag: '36', value: formData.limitCounter, type: 'number' },
      { tag: '37', value: formData.counterPeriod, type: 'number' },
      { tag: '38', value: formData.additionalCounterPeriodData, type: 'number' },
      { tag: '60', value: dayjs(formData.transactionLimitDate).format('YYYYMMDD'), type: 'number' },
    ];

    return tags
      .map(({ tag, value, type }) => {
        const length = value.length.toString().padStart(3, '0');
        const formattedValue =
          type === 'number'
            ? value.padStart(parseInt(length, 10), '0') // Add leading zeroes for numbers
            : value.padEnd(parseInt(length, 10), ' '); // Add trailing spaces for strings
        return `${tag}${length}${formattedValue}`;
      })
      .join('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Effective Date"
            views={['year', 'month', 'day']}
            value={formData.effectiveDate}
            onChange={(newValue) => setFormData({ ...formData, effectiveDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.effectiveDate} helperText={errors.effectiveDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Daily Accumulated Amount Limit"
            name="dailyAmountLimit"
            placeholder="Enter amount"
            required
            variant="outlined"
            value={formData.dailyAmountLimit}
            onChange={handleChange}
            error={!!errors.dailyAmountLimit}
            helperText={errors.dailyAmountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Transaction Amount Limit"
            name="transactionAmountLimit"
            placeholder="Enter transaction amount"
            required
            variant="outlined"
            value={formData.transactionAmountLimit}
            onChange={handleChange}
            error={!!errors.transactionAmountLimit}
            helperText={errors.transactionAmountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Daily Count Limit"
            name="dailyCountLimit"
            placeholder="Enter count"
            required
            variant="outlined"
            value={formData.dailyCountLimit}
            onChange={handleChange}
            error={!!errors.dailyCountLimit}
            helperText={errors.dailyCountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Transaction Limit Expiration Date"
            value={formData.transactionLimitDate}
            onChange={(newValue) => setFormData({ ...formData, transactionLimitDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.transactionLimitDate} helperText={errors.transactionLimitDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            required
            variant="outlined"
            value="P2" // Static value for VIP
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <SignatureGenerator
          header={header}
          body={body}
          onSignatureGenerated={handleSignatureGenerated}
        />
      </Grid>
    </LocalizationProvider>
  );
});

export default VIPList;
